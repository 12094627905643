import React from "react";
import { graphql } from "gatsby";
import Author from "../components/Author";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PostCard from "../components/PostCard";
import styles from "../styles/Archives.module.scss";

class Archives extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const siteDescription = data.site.siteMetadata.description;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location} title={siteTitle} showLogo>
        <SEO title={`${siteTitle} — ${siteDescription}`} />
        <Author showTitle />

        <div className={["content-box", styles.warning].join(" ")}>
          <span
            className={styles.warning__title}
            role="img"
            aria-label="Warning icon"
          >
            ⚠️
          </span>
          <p>
            Les archives contiennent de vieux articles qui ne représentent plus
            nécessairement mon point de vue, mes convictions ou le genre
            d'article que je veux promouvoir sur le blog. Ils sont mis à la
            disposition de tous pour ceux qui auraient déjà visité le blog dans
            le passé et qui recherchent un article qu'ils ont déjà lu.
          </p>
        </div>

        <div className="posts">
          {posts.map(({ node }) => (
            <PostCard key={node.fields.slug} post={node} />
          ))}
        </div>
      </Layout>
    );
  }
}

export default Archives;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { archived: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "D MMMM YYYY", locale: "FR-CA")
            title
          }
        }
      }
    }
  }
`;
